import firebase from "firebase/app";
import { db } from "./firebase";

const DIENSTLEISTUNGEN = "/ecs/dienstleistungen";
const PERSONEN = "/ecs/personen";

interface Dienstleistung {
  name: string;
  bereiche: {
    name: string;
    preisliste: {
      name: string;
      preis: number;
    }[];
  }[];
}

interface Person {
  name: string;
  mail: string;
  phone: string;
  imageUrl: string;
}

export const saveDienstleistungen = async (
  dienstleistungen: Dienstleistung[]
) => await db.doc(DIENSTLEISTUNGEN).update({ dienstleistungen });

export const getDienstleistungen = async (): Promise<Dienstleistung[]> =>
  (await db.doc(DIENSTLEISTUNGEN).get()).data()?.dienstleistungen ?? [];

export const savePersonen = async (personen: Person[]) =>
  await db.doc(PERSONEN).update({ personen });

export const addPerson = async (person: Person) =>
  await db.doc(PERSONEN).update({
    personen: firebase.firestore.FieldValue.arrayUnion(person),
  });

export const getPersonen = async (): Promise<Person[]> =>
  (await db.doc(PERSONEN).get()).data()?.personen ?? [];
