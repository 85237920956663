// Import the functions you need from the SDKs you need

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJXNxIVvHsAefu4C_oUWEVsFyTfAv2P-A",
  authDomain: "electro-control-sueess.firebaseapp.com",
  projectId: "electro-control-sueess",
  storageBucket: "electro-control-sueess.appspot.com",
  messagingSenderId: "134309654658",
  appId: "1:134309654658:web:8a47f84b3a3101b870c49b",
  measurementId: "G-ZQ59HPS1NS",
};

// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);

export const db = fb.firestore();
export const storage = fb.storage();
export const auth = fb.auth();
export const functions = fb.functions();

export default fb;
