import ScrollReveal from "scrollreveal";
import { getPersonen } from "./firestore";

const slideUp = {
  distance: "120px",
  origin: "bottom",
  opacity: 0,
  delay: 50,
  duration: 1000,
  interval: 50,
};

ScrollReveal().reveal(".reveal-up", slideUp);

const template = document.querySelector(".card-template");
const list = document.querySelector(".container");
getPersonen().then((personen) => {
  personen.forEach((person) => {
    const card = template?.cloneNode(true) as HTMLDivElement;
    card.classList.remove("card-template");
    card.classList.add("card");
    card.classList.add("reveal-up-later");
    card.querySelector("img")!.src = person.imageUrl;
    card.querySelector("h3")!.innerText = person.name.toLowerCase();
    card.querySelector(".mail")!.href = `mailto:${person.mail.toLowerCase()}`;
    card.querySelector(".mail > span")!.innerText = person.mail.toLowerCase();
    card.querySelector(".phone")!.href = `tel:${person.phone
      .replaceAll("+", "00")
      .replaceAll(" ", "")}`;
    card.querySelector(".phone > span")!.innerText = person.phone;
    list?.appendChild(card);
  });
  ScrollReveal().reveal(".reveal-up-later", slideUp);
});
